<template>
    <button class="btn btn-danger" @click="deleteTask">Delete</button>
</template>

<script>
export default {
  props: ['task'],

  inject: ['GStore'],

  methods: {
    deleteTask () {
      console.log('Deleted the task')
      this.GStore.flashmessage = 'Task ' + this.task.title + ' was deleted'
      setTimeout(() => {
        this.GStore.flashmessage = ''
      }, 3000)
      this.$router.push({ name: 'TaskDetails' })
    }
  }
}
</script>
